import React, { useCallback, useReducer } from "react"
import checkoutStyles from "./checkout.module.css"
import CheckoutCard from "./CheckoutCard.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, Field, Form, ErrorMessage } from "formik"
import { faTruckMoving } from "@fortawesome/free-solid-svg-icons"
import { calculateJunkRemovalPrice } from "../../data/pricing"
import * as yup from "yup";
import Button from "../common/Button"
import { junkDescriptions } from "../../data/productData";
import { VENDOR_JUNK_REMOVAL } from "../../data/vendors";

function reducer(state, action) {
    switch (action.type) {
      case 'CHECKOUTFLOW':
        return { ui: "CHECKOUTFLOW", data: action.payload} ;
      case 'SUBMITTING':
          return { ui: "SUBMITTING", data: { ...state.data}  }
      case 'FINISHED':
        return { ui: "FINISHED"};
      default:
        return { ui: null};
    }
  }

export default function ({ loadSize, description, previousUrl, vendor }) {
    const normalizedVendorId = typeof vendor === "string" ? parseInt(vendor) : vendor;
    const vendorRecord = VENDOR_JUNK_REMOVAL.find(a => a.id === normalizedVendorId);
    if(!vendorRecord) {
      return <h1>A problem has occured, please try again later</h1>
    }
    let fetchUrl = `https://junkremovaldispatchem.azurewebsites.net/api/LeadSubmission?code=D4q08ao2bhdqaEOPBAyESQGSgPRfyOAZKHF7FmOBlUTtG0qkGiaypA==`;
    if(process.env.NODE_ENV === 'development') {
      fetchUrl += "&dev=1"
    }
    const [state, dispatch] = useReducer(reducer, { ui: null });
    const checkoutCallback = useCallback(async (cc) => {
        dispatch({ type: "SUBMITTING"})
        await fetch(fetchUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(state.data)
          });
        dispatch({ type: "FINISHED"});
    }, [state, dispatch])
    if(state.ui === "SUBMITTING") {
        return <h2>Submitting Request...</h2>
    }
    if(state.ui === "CHECKOUTFLOW") {
        return <CheckoutCard onSubmit={checkoutCallback}/>
    }
    if(state.ui === "FINISHED") {
        return <h2>Thanks for booking with {vendorRecord.name}.  They will reach out to within 24hrs to finalize scheduling.</h2>
    }
  return (
    <>
        <h2>Junk Removal From: {vendorRecord.name}</h2>
        <div className={checkoutStyles.checkoutBox}>
        <Formik
            initialValues={{
            loadSize: loadSize || "25",
            description: description || junkDescriptions[0].value,
            }}
            validationSchema={
                yup.object().shape({
                    email: yup.string().email("Invalid Email").required("Required")
                })
            }
            onSubmit={(v) => {
                dispatch({ type: "CHECKOUTFLOW", payload: v});
            }}
        >{(props) => {
            return (<Form>
                <fieldset className={checkoutStyles.checkboxGroup}>
                    <legend>Truck size</legend>
                    <Field id="25" className={checkoutStyles.radio} type="radio" name="loadSize" value="25" />
                    <label htmlFor="25"><div><FontAwesomeIcon icon={faTruckMoving} /></div>25% Of Truck</label>
                    <Field id="50" className={checkoutStyles.radio} type="radio" name="loadSize" value="50" />
                    <label htmlFor="50"><div><FontAwesomeIcon icon={faTruckMoving} /></div>50% Of Truck</label>
                    <Field id="75" className={checkoutStyles.radio} type="radio" name="loadSize" value="75" />
                    <label htmlFor="75"><div><FontAwesomeIcon icon={faTruckMoving} /></div>75% Of Truck</label>
                    <Field id="100" className={checkoutStyles.radio} type="radio" name="loadSize" value="100" />
                    <label htmlFor="100"><div><FontAwesomeIcon icon={faTruckMoving} /></div>100% Of Truck</label>
                </fieldset>
                <fieldset>
                    <label>Where are items located: <br />           
                        <Field as="select" name="location">
                            <option value="curbside">Curbside</option>
                            <option value="driveway">On Driveway</option>
                            <option value="atABusiness">At a Business</option>
                            <option value="garage">In Garage</option>
                            <option value="basement">In Basement</option>
                            <option value="house">Inside House</option>
                            <option value="backyard">Backyard</option>
                            <option value="apptComplex">Apartment Complex</option>
                        </Field>
                    </label>
                    <br />
                    <label>How soon do you want to schedule?: <br /> <Field name="schedule" type="date" /></label><br />
                    <label>Full Name: <br /> <Field name="name" type="text" /></label><br />
                    <label>Phone Number: <br /> <Field name="number" type="tel" /></label><br />
                    <label>Street Address: <br /> <Field name="address1" type="text" /></label><br />
                    <label>Zip code: <br /> <Field name="zip" type="text" /></label><br />
                    <label>Email Address: <br /> <Field name="email" type="email" /></label>{" "}<ErrorMessage name="email" /><br />
                    <label>Anything we should know? <br /><Field name="Additional Information" type="text" /></label> <br />
                    <b>Your price is ${calculateJunkRemovalPrice(props.values.loadSize, normalizedVendorId)}</b><br />
                    <Button type="submit">Submit</Button>
                </fieldset>
                </Form> ) 
        }}
        </Formik>
        </div>
    </>
  )
}
