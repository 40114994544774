import React from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as yup from "yup"
import Button from "../common/Button"


function addGaps(str, gapNo) {
  let newStr = " "
  let len = str.length
  for (let i = 0; i < len; i++) {
    newStr = newStr + str[i]
    while (newStr.length % (gapNo + 1) === 0) {
      newStr = newStr + " "
    }
  }
  return newStr.trim(" ")
}

export default function CheckoutCard({ onSubmit }) {
  const dates = [...Array(13).keys()]
  dates.shift()
  return (
    <Formik
      initialValues={{
        cc: "",
        name: "",
        dateMonth: 1,
        dateYear: new Date().getFullYear() + 4,
        cvv: ""
      }}
      validationSchema={yup.object().shape({
        cc: yup.string().min(18).max(20).label("Invalid credit card number"),
        name: yup.string().required("Name is required"),
        dateMonth: yup.number().min(1).max(12),
        dateYear: yup.number().min(2020).required("Year required"),
        cvv: yup.string().required("required")
      })}
      onSubmit={onSubmit}
    >
      <Form>
        <label>
          Credit Card Number:{" "}
          <Field name="cc">
            {({
              field, // { name, value, onChange, onBlur }
              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
              meta,
            }) => (
              <div>
                <input
                  maxLength="19"
                  type="text"
                  {...field}
                  value={addGaps(field.value.replace(/\s/g, ""), 4)}
                />
                {meta.touched && meta.error && (
                  <div className="error">{meta.error}</div>
                )}
              </div>
            )}
          </Field>
        </label>
        <label>cvv:<br /> <Field name="cvv" type="text" /></label><br />
        <label>
          Name on card:{" "}<br />
          <div>
            <Field type="text" name="name" />
            <ErrorMessage name="name" />
          </div>
        </label>
        <label>
          Expiration date:{" "}
          <div>
            <Field as="select" name="dateMonth">
              {dates.map(i => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </Field>{" "}
            Year: <Field type="number" name="dateYear"></Field>{" "}
            <ErrorMessage name="dateMonth" />
            <ErrorMessage name="dateYear" />
          </div>
        </label>
        <br />
        <Button type="submit">Submit</Button>
      </Form>
    </Formik>
  )
}
