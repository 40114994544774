import React, { useCallback, useReducer } from "react"
import CheckoutCard from "./CheckoutCard.js";
import checkoutStyles from "./checkout.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, Field, Form, ErrorMessage } from "formik"
import { faDumpster } from "@fortawesome/free-solid-svg-icons"
import { calculateDumpsterRemovalPrice } from "../../data/pricing"
import * as yup from "yup";
import Button from "../common/Button"
import { VENDOR_DUMPSTER_RENTAL } from "../../data/vendors";


function reducer(state, action) {
    switch (action.type) {
      case 'CHECKOUTFLOW':
        return { ui: "CHECKOUTFLOW", data: action.payload};
      case 'SUBMITTING':
        return { ui: "SUBMITTING", data: { ...state.data}  };
      case 'FINISHED':
        return { ui: "FINISHED"};
      default:
        return { ui: null};
    }
  }

export default function ({ cubicYardSize, rentalDays, previousUrl, vendor }) {
  const normalizedVendorId = typeof vendor === "string" ? parseInt(vendor) : vendor;
  const vendorRecord = VENDOR_DUMPSTER_RENTAL.find(a => a.id === normalizedVendorId);
  if(!vendorRecord) {
    return <h1>A problem has occured, please try again later</h1>
  }
    let fetchUrl = `https://junkremovaldispatchem.azurewebsites.net/api/LeadSubmission?code=D4q08ao2bhdqaEOPBAyESQGSgPRfyOAZKHF7FmOBlUTtG0qkGiaypA==`;
    if(process.env.NODE_ENV === 'development') {
      fetchUrl += "&dev=1"
    }
    const [state, dispatch] = useReducer(reducer, { ui: null});
    const checkoutCallback = useCallback(async (cc) => {
        dispatch({ type: "SUBMITTING"})
        await fetch(fetchUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(state.data)
          });
        dispatch({ type: "FINISHED"});
    }, [state, dispatch])
    if(state.ui === "SUBMITTING") {
        return <h2>Submitting Request...</h2>
    }
    if(state.ui === "CHECKOUTFLOW") {
        return <CheckoutCard onSubmit={checkoutCallback}/>
    }
    if(state.ui === "FINISHED") {
        return <h2>Thanks for booking with {vendorRecord.name}.  They will reach out to within 24hrs to finalize scheduling.</h2>
    }
  return (
      <>
        <h2>Dumpster Rental From: {vendorRecord.name}</h2>
        <div className={checkoutStyles.checkoutBox}>
        <Formik
            initialValues={{
            cubicYardSize: cubicYardSize || "10",
            rentalDays: rentalDays || rentalDays,
            }}
            validationSchema={
                () => yup.object().shape({
                    email: yup.string().email("Invalid Email").required("Required")
                })
            }
            onSubmit={(v) => {
                dispatch({ type: "CHECKOUTFLOW", payload: v});
            }}
        >{(props) => {
            return (<Form>
                <fieldset className={checkoutStyles.checkboxGroup}>
                    <legend>Cubic Yard Size</legend>
                    <Field id="10" className={checkoutStyles.radio} type="radio" name="cubicYardSize" value="10" />
                    <label htmlFor="10"><div><FontAwesomeIcon icon={faDumpster} /></div>10 Cubic Yards</label>
                    <Field id="15" className={checkoutStyles.radio} type="radio" name="cubicYardSize" value="15" />
                    <label htmlFor="15"><div><FontAwesomeIcon icon={faDumpster} /></div>15 Cubic Yards</label>
                    <Field id="20" className={checkoutStyles.radio} type="radio" name="cubicYardSize" value="20" />
                    <label htmlFor="20"><div><FontAwesomeIcon icon={faDumpster} /></div>20 Cubic Yards</label>
                </fieldset>
                <fieldset>
                    <label>How many days will you need: <br /> <Field name="rentalDays" type="number" /></label><br />
                    <label>How soon do you want to schedule?: <br /> <Field name="schedule" type="date" /></label><br />
                    <label>Full Name: <br /> <Field name="name" type="text" /></label><br />
                    <label>Phone Number: <br /> <Field name="number" type="text" /></label><br />
                    <label>Street Address: <br /> <Field name="address1" type="text" /></label><br />
                    <label>Zip code: <br /> <Field name="zip" type="text" /></label><br />
                    <label>Email Address: <br /> <Field name="email" type="email" /></label>{" "}<ErrorMessage name="email" /><br />
                    <label>Anything we should know? <br /><Field name="Additional Information" type="text" /></label> <br />
                    <b>Your price is ${calculateDumpsterRemovalPrice(props.values.cubicYardSize, props.values.rentalDays, normalizedVendorId) }</b><br />
                    <Button type="submit">Submit</Button>
                </fieldset>
                </Form> ) 
        }}
        </Formik>
        </div>
        </>
  )
}
