import React, { useEffect, useState } from "react"
import CheckoutJunk from "../components/checkout/CheckoutJunk"
import CheckoutDumpster from "../components/checkout/CheckoutDumpster"

import Layout from "../components/layout"
import { TYPE_DUMPSTER_RENTAL, TYPE_JUNK_REMOVAL } from "../components/listings/constants"
import SEO from "../components/seo"

export default function Checkout({ location }) {
    const search = location?.search
    const searchParams = Object.fromEntries(new URLSearchParams(search))
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, [])
   return (
    <Layout fluid={false}>
        <SEO title="Checkout" />
        {loading && <h2>Loading...</h2>}
        {!loading && searchParams.type === TYPE_JUNK_REMOVAL && <CheckoutJunk vendor={searchParams.vendor} previousUrl={searchParams.previousUrl} loadSize={searchParams.loadSize} description={searchParams.description}  />}
        {!loading && searchParams.type === TYPE_DUMPSTER_RENTAL && <CheckoutDumpster vendor={searchParams.vendor} previousUrl={searchParams.previousUrl} cubicYardSize={searchParams.cubicYardSize} rentalDays={searchParams.rentalDays}  />}
    </Layout>
   ) 
}