// TODO: Put this in a database of some kind

export const VENDOR_JUNK_REMOVAL = [
  { price: { 25: 125, 50: 250, 75: 375, 100: 500 }, id: 1, name: "Hullaway LLC", rating: 5, workHours: "7AM - 9PM", summary: "The name Hullaway is derived from one of the founder's name, Chad Hull. The name lets you know what we do with your unwanted junk and debris...haul it away." },
  { price: { 25: 100, 50: 225, 75: 350, 100: 475 }, id: 2, name: "Junk Octopus 🐙", rating: 4, workHours: "8AM - 8PM" },
  { price: { 25: 150, 50: 300, 75: 450, 100: 600 }, id: 3, name: "G.I. Junk", rating: 4, workHours: "6AM - 6PM" },
  { price: { 25: 85, 50: 175, 75: 300, 100: 425 }, id: 4, name: "Awesome Junk Guys", rating: 2, workHours: "9AM - 9PM"}
]
export const VENDOR_DUMPSTER_RENTAL = [
    { price: { 10: 275, 15: 325, 20: 375 }, id: 1, name: "Hullaway LLC", rating: 5, workHours: "7AM - 9PM", summary: "The name Hullaway is derived from one of the founder's name, Chad Hull. The name lets you know what we do with your unwanted junk and debris...haul it away." },
    { price: { 10: 275, 15: 325, 20: 375 }, id: 2, name: "Junk Octopus 🐙", rating: 4, workHours: "8AM - 8PM" },
    { price: { 10: 325, 15: 350, 20: 400 }, id: 3, name: "Acme Dumpsters", rating: 5, workHours: "6AM - 6PM" },
    { price: { 10: 250, 15: 300, 20: 350 }, id: 4, name: "The Dumpster Guys", rating: 4, workHours: "5AM - 9PM", summary: "These guys don't stop working for you to get rid of your junk!"}
]