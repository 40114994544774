export const junkDescriptions = [
    {
        name: "Furniture",
        value: "furniture"
    },
    {
        name: "Appliances",
        value: "appliances"
    },
    {
        name: "Construction Debris",
        value: "constructionDebris"
    },
    {
        name: "Yard Debris",
        value: "yardDebris"
    },
    {
        name: "Garbage",
        value: "garbage"
    }
]