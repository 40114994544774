import { VENDOR_DUMPSTER_RENTAL, VENDOR_JUNK_REMOVAL } from './vendors.js';

export function calculateJunkRemovalPrice(loadSize, vendorId) {
  if(typeof vendorId === "string") {
    vendorId = parseInt(vendorId);
  }
  // TODO: error handle
  return VENDOR_JUNK_REMOVAL.find(i => i.id === vendorId).price[loadSize];
}

export function calculateDumpsterRemovalPrice(cubicYardSize, rentalDays, vendorId) {
  if(typeof vendorId === "string") {
    vendorId = parseInt(vendorId);
  }
  // TODO: error handle
  const dumsterPrice = VENDOR_DUMPSTER_RENTAL.find(i => i.id === vendorId).price[cubicYardSize]
  if (rentalDays > 5) {
    // each additional day is an extra 25 dollars
    const numberOfDaysCharge = 25 * (rentalDays - 5)
    return dumsterPrice + numberOfDaysCharge
  }
  return dumsterPrice
}
